@import "structure/base";
@import "structure/reset";
@import "structure/fonts";
@import "structure/icon-style";
//@import "structure/common";

$font-base-url: '../assets/fonts/';

/* Thin */
@font-face {
  font-family: 'Overpass';
  font-weight: 100;
  font-style: normal;
  src: url($font-base-url + 'Overpass-Thin.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 100;
  font-style: italic;
  src: url($font-base-url + 'Overpass-ThinItalic.ttf');
}

/* Extra-light */

@font-face {
  font-family: 'Overpass';
  font-weight: 200;
  font-style: normal;
  src: url($font-base-url + 'Overpass-ExtraLight.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 200;
  font-style: italic;
  src: url($font-base-url + 'Overpass-ExtraLightItalic.ttf');
}

/* Light */

@font-face {
  font-family: 'Overpass';
  font-weight: 300;
  font-style: normal;
  src: url($font-base-url + 'Overpass-Light.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 300;
  font-style: italic;
  src: url($font-base-url + 'Overpass-LightItalic.ttf');
}

/* Regular */

@font-face {
  font-family: 'Overpass';
  font-weight: 400;
  font-style: normal;
  src: url($font-base-url + 'Overpass-Regular.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 400;
  font-style: italic;
  src: url($font-base-url + 'Overpass-Regular.ttf');
}

/* Semi-bold */

@font-face {
  font-family: 'Overpass';
  font-weight: 600;
  font-style: normal;
  src: url($font-base-url + 'Overpass-SemiBold.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 600;
  font-style: italic;
  src: url($font-base-url + 'Overpass-SemiBoldItalic.ttf');
}

/* Bold */

@font-face {
  font-family: 'Overpass';
  font-weight: 700;
  font-style: normal;
  src: url($font-base-url + 'Overpass-Bold.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 700;
  font-style: italic;
  src: url($font-base-url + 'Overpass-BoldItalic.ttf');
}

/* Extra-bold */

@font-face {
  font-family: 'Overpass';
  font-weight: 800;
  font-style: normal;
  src: url($font-base-url + 'Overpass-ExtraBold.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 800;
  font-style: italic;
  src: url($font-base-url + 'Overpass-ExtraBoldItalic.ttf');
}

/* Black */

@font-face {
  font-family: 'Overpass';
  font-weight: 900;
  font-style: normal;
  src: url($font-base-url + 'Overpass-Black.ttf');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 900;
  font-style: italic;
  src: url($font-base-url + 'Overpass-BlackItalic.ttf');
}



body {
  background-color: #ffffff;
  overflow-x: hidden;
  font-family: "Arial", sans-serif;
}
